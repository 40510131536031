<template>
  <div class="look_wearing">
    <back-step
      :goList="[{
        url: '/cases',
        name: $t('casesDetail.casesChildren.common.bl')
      }, {
        url: '/cases/detail',
        name: $t('casesDetail.casesChildren.common.blxq')
      }]"
      :currentTxt="$t('casesDetail.casesChildren.lookWearingRecord.ckpdjl')"
    />
    <div class="look_wearing_head">
      <div class="head_left">
        <h5 class="head_h5">{{$t('casesDetail.casesChildren.lookWearingRecord.dqpd')}}：{{$t('casesDetail.casesChildren.lookWearingRecord.d')}}{{wearRecordObj.nowWearSet}}{{$t('casesDetail.casesChildren.lookWearingRecord.f')}}</h5>
        <p class="head_p">
          <span class="head_sp">{{$t('casesDetail.casesChildren.lookWearingRecord.kspdsj')}}：{{wearRecord[0] && wearRecord[0].startDatetime}}</span>
          <span class="head_sp">{{$t('casesDetail.casesChildren.lookWearingRecord.yjssj')}}：{{wearRecord[0] && wearRecord[0].endDatetime}}</span>
        </p>
      </div>
      <div class="head_right">
        <h5 class="head_h5 head_h5_b">
          {{lang === 'zh_CN' ? `当前批次还剩${wearRecordObj.batchRemainWearSet}套` : `${wearRecordObj.batchRemainWearSet} set left in current batch`}}
        </h5>
        <div class="head_p">
          <span class="head_sp">{{$t('casesDetail.casesChildren.lookWearingRecord.xytpd')}}：{{wearRecordObj.nextWearRemainDatetime}}</span>
          <div class="main_theme_color_btn main_big_btn curp">
            {{$t('casesDetail.casesChildren.lookWearingRecord.ggtxsj')}}
          </div>
          <div class="head_picker curp">
            <el-date-picker
              v-model="nextWearRemainDatetime"
              type="datetime"
              :placeholder="$t('casesDetail.casesChildren.lookWearingRecord.xzrq')"
              @change="changeReminderTime"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
    <div class="look_wearing_box">
      <ul class="look_box_ul" v-if="wearRecord.length">
        <li
          class="right_li"
          v-for="item in wearRecord"
          :key="item.id"
        >
          <div class="r_li_left">
            {{item.startDatetime}}
          </div>
          <div class="r_li_right">
            <h5 class="right_li_h5">
              <span class="r_li_icon">{{item.set}}</span>
              {{lang === 'zh_CN' ? `第${item.set}副` : `${item.set} pair`}}
            </h5>
            <div class="right_li_box">
              <p class="right_li_p">{{item.remark}}</p>
            </div>
          </div>
        </li>
      </ul>
      <div class="look_wearing_no" v-else>
        {{$t('casesDetail.casesChildren.lookWearingRecord.zwpdjl')}}
      </div>
    </div>
    <FullLoading v-show="isLoading"/>
  </div>
</template>

<script>
  import BackStep from '../childrenPublic/backStep';
  import FullLoading from 'components/full-loading/full-loading';
  import {casesDetail, wearRecordList, modifyNextDatetime} from 'common/api/cases';
  import {notifyMsg, formatDate} from 'common/js/util';
  import {mapState} from 'vuex';

  export default {
    data() {
      return {
        wearRecordObj: {},
        wearRecord: [],
        caseId: '',
        nextWearRemainDatetime: '',
        isLoading: false,
        lang: localStorage.getItem('user_lang') || 'zh_CN'
      }
    },
    created() {
      document.title = this.$t('casesDetail.casesChildren.lookWearingRecord.ckpdjl');
      const caseId = localStorage.getItem('casesId') || sessionStorage.getItem('casesId') || '';
      const curePlanId = localStorage.getItem('curePlanId') || sessionStorage.getItem('curePlanId') || '';
      sessionStorage.setItem('casesId', caseId);
      sessionStorage.setItem('curePlanId', curePlanId);
      localStorage.removeItem('casesId');
      localStorage.removeItem('curePlanId');
      if(caseId) {
        this.caseId = caseId;
        this.getCasesDetail();
        wearRecordList({caseId}).then(data => {
          this.wearRecord = data.map(item => ({
            ...item,
            startDatetime: formatDate(item.startDatetime, 'yyyy-MM-dd'),
            endDatetime: formatDate(item.endDatetime, 'yyyy-MM-dd')
          }));
        });
      }
    },
    methods: {
      changeReminderTime() {
        modifyNextDatetime({
          id: this.caseId,
          nextWearRemainDatetime: formatDate(this.nextWearRemainDatetime)
        }).then(() => {
          this.getCasesDetail();
        });
      },
      getCasesDetail() {
        this.isLoading = true;
        casesDetail(this.caseId).then(data => {
          this.isLoading = false;
          this.wearRecordObj = data;
        }).catch(() => {
          this.isLoading = false;
        });
      }
    },
    computed:{
      ...mapState({
        language: state => state.language,
      }),
    },
    components: {
      BackStep,
      FullLoading
    }
  }
</script>

<style scoped lang="scss">
  .look_wearing{
    width: 13.44rem;
    margin: 0 auto;
    font-size: 0.16rem;
    color: $main_theme_color_333;
    .look_wearing_head{
      background-color: #fff;
      border-radius: 0.08rem;
      padding: 0.36rem 0.77rem 0.25rem 0.6rem;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.2rem;
      .head_h5{
        color: $main_theme_color_333;
        font-size: 0.2rem;
        margin-bottom: 0.18rem;
      }
      .head_h5_b{
        font-size: 0.2rem;
        margin-bottom: 0.08rem;
      }
      .head_p{
        display: flex;
        align-items: center;
        position: relative;
        font-size: 0.16rem;
        .head_picker{
          position: absolute;
          bottom: 0;
          right: 0;
          width: 1.64rem;
          overflow: hidden;
          opacity: 0;
          cursor: pointer;
        }
      }
      .head_sp{
        margin-right: 0.35rem;
      }
      .head_btn{
        background-color: $main_theme_color;
        color: #fff;
        border-radius: 1rem;
        padding: 0.12rem 0.25rem;
        cursor: pointer;
      }
    }
    .look_wearing_box{
      background-color: #fff;
      border-radius: 0.1rem;
      padding: 0.4rem 0.59rem 0.45rem;
      font-size: 0.14rem;
      .right_li{
        display: flex;
        .r_li_left{
          margin-right: 0.27rem;
          padding-top: 0.03rem;
          box-sizing: border-box;
        }
        .r_li_right{
          .right_li_h5{
            display: flex;
            align-items: center;
            color: $main_theme_color_333;
            font-size: 0.16rem;
            font-weight:bold;
            margin-left: -0.1rem;
            span{
              display: inline-block;
              background-color: $main_theme_color;
              color: #fff;
              font-weight:bold;
              width: 0.2rem;
              height: 0.2rem;
              margin-right: 0.1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              font-size: 0.12rem;
            }
          }
          .right_li_box{
            font-size: 0.14rem;
            border-left: 0.01rem solid #DADADA;
            padding: 0.2rem 0 0.5rem 0.2rem;
            color: #999999;
            box-sizing: border-box;
          }
        }
        &:last-child .r_li_right .right_li_box{
          border: none
        }
      }
      .look_wearing_no{
        padding: 0.8rem 0;
        text-align: center;
        color: #666666;
        font-size: 0.17rem;
      }
    }
  }
</style>
